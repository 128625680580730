<template>
    <div>
        <Navbar :page="`Diagnósticos ${ form.tema ? form.tema : '' }`" link="/diagnosticos" nameLink="Diagnósticos" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-5">
                                <label for="name" class="block text-sm font-medium">Tema</label>
                                <input type="text" v-model="form.tema" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12">
                                <label for="atribuirPontos" class="block text-sm font-medium">
                                    <input type="checkbox" v-model="form.atribuirPontos" class="rounded-sm" id="atribuirPontos">
                                    <span class="ml-2">
                                        Atribuir pesos às questões, manualmente
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <hr class="pb-4 mx-5"/>

                    <div class="px-5 pb-4">
                        <button type="button" @click="addPergunta" class="inline-flex justify-center py-2 px-6 mb-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                            Adicionar pergunta
                        </button>
                        <button type="button" @click="$modal.show('importarModal')" class="inline-flex justify-center py-2 px-6 mb-5 md:ml-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg> 
                            Importar perguntas 
                        </button>
                        <a v-if="$route.params.id && form.perguntas.length > 0" :href="`${url_api}/v1/${route}/download/${$route.params.id}?token=${$store.state.token}`" type="button" role="button" target= "_blank" rel="noreferrer" class="inline-flex justify-center py-2 px-6 mb-5 md:ml-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                            </svg> 
                            Download perguntas 
                        </a>
                        <button type="button" @click="removerPerguntas" class="inline-flex justify-center py-2 px-6 mb-5 md:ml-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            Removar todas as perguntas
                        </button>
                        <div v-for="(item, idx) in form.perguntas" :key="idx" class="grid grid-cols-12 gap-6 my-1 md:my-3">
                            <div class="col-span-12 md:col-span-2">
                                <label class="block text-sm font-medium">REF: {{ item.ref }}</label>
                                <button type="button" @click="remove(item, idx)" class="text-white text-sm hover:bg-red-600 bg-red-500 rounded py-2 px-3 my-1 md:my-3">Remover Campo</button>
                            </div>
                            <div class="col-span-12 md:col-span-8">
                                <label for="pergunta" class="block text-sm font-medium">Pergunta</label>
                                <textarea type="text" rows="3" v-model="item.pergunta" name="pergunta" id="pergunta" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>
                            <div v-if="form.atribuirPontos" class="col-span-12 md:col-span-2">
                                <label class="block text-sm font-medium my-2">Peso: </label>
                                <div class="flex">
                                    <button type="button" @click="changePontuacao(item,'adicionar')" class="bg-green-600 text-white px-3 h-8 text-base font-medium text-center border-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </button>
                                    <div class="mx-2">
                                        <input v-model="item.pontuacao" disabled class="w-14 h-8 text-base font-medium text-center border-2">
                                    </div>
                                    <button type="button" @click="changePontuacao(item,'diminuir')" class="bg-red-600 text-white px-3 h-8 text-base font-medium text-center border-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
            <modal name="importarModal" :adaptive="true" :height="'auto'">
                <div class="px-5 py-5">
                    <h2 class="text-xl mb-2 font-bold mb-2"> Importar base</h2>
                    <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <a target= "_blank" rel="noreferrer" :href="`/arquivo-modelo-11.csv`" class="text-sm underline">Exemplo arquivo modelo importação</a>
                    </div>
                    <div class="col-span-12">
                        <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update">
                        </vue-dropzone>
                    </div>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
    components: {
        vueDropzone
    },
    data() {
        return {
            route: 'diagnosticos',
            idx: 0,
            form: {
                tema: '',
                perguntas: [],
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/diagnosticos/importar-csv`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
                dictRemoveFile: 'Remover'
            })
        }
    },
    methods: {
        addPergunta() {
            this.idx = this.form.perguntas.length + 1; 
            this.form.perguntas.push({ ref: this.idx, pergunta: '', pontuacao: 1 });
            window.scrollTo(0, document.body.scrollHeight);
        },
        async save() {
            if(!this.form.tema) return this.$vToastify.error("Digite o nome do tema");
            if(!this.form._id) {
                const req = await this.$http.post(`/v1/${this.route}`, this.form);
                if (req.data.success) {
                    this.$vToastify.success("Salvo com sucesso!");
                    this.$router.back();
                } else {
                    this.$vToastify.error(req.data.err);
                }
                return
            }
            const reqput = await this.$http.put(`/v1/${this.route}`, { _id: this.form._id, atribuirPontos: this.form.atribuirPontos, tema : this.form.tema });
            if (reqput.data.success) {
                for(let i = 0; i < this.form.perguntas.length; i++){
                    const pergunta = this.form.perguntas[i];
                    if(!pergunta.diagnostico) pergunta.diagnostico = this.form._id;
                }
                await this.$http.put(`/${this.route}/perguntas/edit`, { perguntas: this.form.perguntas, diagnostico: this.form._id });
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(reqput.data.err);
            }
        },
        async remove(pergunta,idx){
            this.form.perguntas.splice(idx, 1); 
            idx = this.form.perguntas.length + 1;
            if(pergunta._id){
                pergunta.ativo = false;
                await this.$http.put(`/${this.route}/pergunta/edit`, pergunta);
            }
        },
        async removerPerguntas(){
            this.$confirm({
            title: 'Confirmação',
            message: `Tem certeza que deseja remover todas as perguntas do diagnóstico?`,
            button: {
                no: 'Não',
                yes: 'Sim',
            },
            callback: async confirm => {
                if (confirm) {
                    await this.$http.post(`/v1/${this.route}/perguntas/delete`, { _id: this.$route.params.id });
                    this.$vToastify.success("Removido com sucesso!");
                    this.form.perguntas = [];
                }
            }
        });
        },
        changePontuacao(p, change) {
            if(change === 'diminuir' && p.pontuacao > 0) p.pontuacao--;
            if(change === 'adicionar') p.pontuacao++;
        },
        send(file, xhr, formData) {
            formData.append('empresa', this.$store.state.empresa && this.$store.state.empresa._id ? this.$store.state.empresa._id : null);
            formData.append('idx', this.form.perguntas.length);
        },
        async update(file, response) {
            if(!response || !response.perguntas) return;
            for(let i = 0; i < response.perguntas.length; i++){
                const perguntas = response.perguntas[i];
                this.form.perguntas.push(perguntas);
            }
            this.$modal.hide('importarModal');
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/get-diagnosticos/${id}`);
            this.form = req.data.data;
            this.form.perguntas = req.data.perguntas;
        }
    },
}
</script>
